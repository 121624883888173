import config from "../config";
import { reactLocalStorage } from "reactjs-localstorage";
export const hitCallBackApi = (num, camp, rcid) => {
    const url = "https://camp-reports.bngrenew.com:5455/digitalservice/api/sendcallback";
    const headers = {
        'Content-Type': 'application/json'
    };

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({ 'campid': camp, 'rcid': rcid, 'msisdn': num,"opco": "dna_id_ayn" }),
        headers: headers
    })
    .then(res => {
        if (!res.ok) { 
            throw res;
        }
        return res.text();
    })
    .then((result) => {
        if (result === "ok") {
            reactLocalStorage.remove("camp");
            reactLocalStorage.remove("rcid");
            reactLocalStorage.remove("initStatus");
        }
        return result;
    })
    .catch(error => {
        console.log('error:::', error);
        throw error;
    });
};
