import React,{useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

const ProtectedRoute = ({ component: Component,...rest }) => {
  const uid=reactLocalStorage.get("uid")
  const msisdn=reactLocalStorage.get("msisdn")
  const userStatus=reactLocalStorage.get("subStatus")
  let  isAuthenticated  = ((uid || msisdn) && userStatus==="active");

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAuthenticated ? 
            <Component {...routeProps} /> 
  
         : (
          <Redirect to="/login" />
        )
      }
    />
  )
};

export default ProtectedRoute;
