import './App.scss';
import 'bulma/css/bulma.css'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import Header from './commonComponents/Header'
import Home from "./AppComponents/Home"
import Game from "./AppComponents/Game"
import FillOtp from './AppComponents/FillOtp';
import Subscription from './AppComponents/Subscription';
import TermsAndConditions from './commonComponents/TermsandCondition';
import Sorry from './commonComponents/Sorry';
import NotFound from "./AppComponents/NotFound"
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useLanguage } from './Context/LangContext';
import _ from "lodash";
import { reactLocalStorage } from 'reactjs-localstorage';
import config from "./config"
import Category from './commonComponents/Category';
import Profile from "./AppComponents/Profile";
import Unsubscribe from './AppComponents/Unsubscribe';
import Language from './commonComponents/Language';
import ProtectedRoute from './ProtectedRoute';
import HomeLoader from './AppComponents/HomeLoader';
import queryString from 'query-string';
import { screen } from './Analytics/EventName';
import { logEvent,logEventHome } from './Analytics/AnalyticsEvent';
import SubscriptionLoader from './AppComponents/SubscriptionLoader';
import SubscriptionModal from './AppComponents/SubsriptionModal';
import { hitCallBackApi } from './hooks/useHitCallBackApi';
import { SendGuiDataEvents } from './CommonSript';
function App() {
      const {language}=useLanguage();
      const [sideMenu, setSideMenu] = useState(false)
      const [showSideMenu, setshowSideMenu] = useState(true);
      const [loading , setLoading] = useState(true)
      const [banners,setBanners]=useState([])
      const [games,setGames]=useState([])
      const match1 = useRouteMatch('/game/:id')
      const [appConfigData, setappConfigData] = useState({})
      const history = useHistory();
      const sendHomeOpenEvent=async ()=>
        {
            let guiEvents = {};
            guiEvents["page"] = "home";
            guiEvents["event"] = "open";
            await SendGuiDataEvents(guiEvents);
        }
        
      const handleApiCall = (num,camp,rcid) => {
        hitCallBackApi(num, camp, rcid)
            .then(result => {
                console.log('API call successful:', result);
                setLoading(false)
                history.push("/home")
            })
            .catch(error => {
                console.error('API call failed:', error);
            });
    };
      let { search } = useLocation();
      const { uid,rcid,camp } = queryString.parse(search);
      const {setAllGamesJson}=useLanguage()
  
      useEffect(() => {
        if(rcid && camp)
        {
          reactLocalStorage.set('camp',camp)
          reactLocalStorage.set('rcid',rcid)
        }
            console.log("Analytics initialize")
            const ga_tag = config.get("ga_tag");
            const ga_debug = config.get("ga_debug");
            console.log("GOOGLE ANALYTICS DEBUG :: ", ga_tag, " :: ", ga_debug);
            ReactGA.initialize(ga_tag, {
              debug: ga_debug,
              titleCase: false,
            });
            // to report page view
            history.listen((location) => {
              ReactGA.set({ page: location.pathname });
              ReactGA.pageview(location.pathname);
            });
          }, []);

          const checksub = async(num) => {
            if(num && num!=='undefined'){
            let guiEvents = {};
            guiEvents["page"] = "home";
            guiEvents["event"] = "checksub_request";
            SendGuiDataEvents(guiEvents);
            const url = `${config.get('base')}${config.get('checkSub')}`
            const headers={
                ...config.get('headers'),
                Language:language
            }
            try {
                  const res = await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({ uid: num }),
                    headers,
                  });
            
                  if (!res.ok) throw res;
            
                  const result = await res.json();
                  reactLocalStorage.set('subStatus', result?.subStatus);
                  reactLocalStorage.set('status_display', result?.status_display);
                  reactLocalStorage.set('showUnsub', result?.showUnsub);
                  reactLocalStorage.set('msisdn', result?.msisdn);
                  reactLocalStorage.set('unsubtext', result?.unsub_msg_txt);
                  let guiEvents1 = {};
                  guiEvents1["page"] = "home";
                  guiEvents1["event"] = "checksub_response";
                  guiEvents1["status"] = result?.subStatus;
                  SendGuiDataEvents(guiEvents1);
                  if (result?.status === 'success') {
                    if(result?.subStatus==="new")
                    {
                       reactLocalStorage.set("initStatus",'new')
                    }
                    else if (result?.subStatus === 'active') {
                      const campId=camp || reactLocalStorage.get("camp");
                      const  rcId=rcid || reactLocalStorage.get("rcid")
                      if((campId  && rcId) && reactLocalStorage.get("initStatus")==='new')
                      {
                        handleApiCall(num, camp, rcid)
                      }
                      history.push("/home")
                      console.log("user is free to use service")
                    } else {
                      history.push('/login');
                    }
                  } 
                  setLoading(false)
                  logEventHome(
                    {
                      screen: screen.homePage,
                      event: 'checksub_api_success',
                    },
                    {
                      status: result.subStatus || '',
                      msisdn: result.msisdn || 'NA',
                    }
                  );
                } catch (error) {
                  let guiEventsError = {};
                  guiEventsError["page"] = "home";
                  guiEventsError["event"] = "checksub_response_error";
                  guiEventsError["error_status"] = error.status || "unknown";
                  guiEventsError["error_message"] = error.statusText || "Error occurred during checksub";
                  SendGuiDataEvents(guiEventsError);
                  console.log('Error during subscription check:', error);
                }
            }
        };      
      useEffect(() => {
            const fetchConfigAndCheckSub = async () => {
              const isPinVerified=reactLocalStorage.get("pinVerified");
              try {
                await getAppConfigData();
                if (uid !== '' && uid !== undefined && isPinVerified==='true' ) {
                  reactLocalStorage.set("uid", uid);
                  sendHomeOpenEvent()
                  await checksub(uid);
                } else {
                  const storedUid = reactLocalStorage.get("uid");
                  if (storedUid && isPinVerified==='true') {
                    sendHomeOpenEvent()
                    await checksub(storedUid);
                  } else {
                    setLoading(false);
                  }
                }
              } catch (error) {
                console.log('Error during config and subscription check:', error);
                setLoading(false);
              }
            };
        
            fetchConfigAndCheckSub();
          }, [language]);
      const getAppConfigData =async  () => {
            setLoading(true)
            const url = `${config.get('base')}${config.get('appConfig')}`;
            const headers={
                  ...config.get('headers'),
                  Language:language
            }
           const response =await  fetch(url, {
                  method: 'POST',
                  body: JSON.stringify({ "mcc": "652", "mnc": "04" ,"uid" : reactLocalStorage.get("uid") ? reactLocalStorage.get("uid") : ""}),
                  headers:headers
            })
            if (!response.ok) {
              throw new Error('Failed to fetch AppConfig data');
          }
                        const result = await response.json();
                        console.log('result message appConfig ::', result)
                        const appConfigObj = {
                              menu: result.menu,
                              menu_color: result.menu_color,
                              home_logo: result.home_logo,
                              menu_logo: result.menu_logo,
                              view_all:result.view_all,
                              bg_color:result.bkg_color,
                              play_text:result.play_btn_txt,
                              flow:result.subRedirectFlow,
                              view_btn_txt:result.view_btn_txt,
                              sub_url:result.sub_url,
                              unsub_mode:result.unsubMode,
                              sidemenu:result.sidemenu,
                              unsub_msg_txt:result.unsubData,
                              portrait_txt:result.portrait_txt,
                              rotate_txt:result.rotate_txt,
                              landscape_txt:result.landscape_txt,
                              status_txt:result.status_txt,
                              similar_cat_txt:result.similar_cat_txt
                        }
                        setappConfigData(appConfigObj)
                        setAllGamesJson(result?.games_list)
                        setGames(result?.games_list)
                        setBanners(result.banner)
                  
      }

if(loading) return <HomeLoader/>;
      return (
            <section className="hero is-fullheight bg">
                  {!(match1)  &&  <Header loading={loading} 
                  menuBar = {appConfigData.menu} 
                  sub_url={appConfigData.sub_url}
                  sidemenu={appConfigData?.sidemenu}
                  menuLogo={appConfigData.menu_logo} 
                  homeLogo={appConfigData.home_logo} 
                  handleClick={() => setSideMenu(!sideMenu)}
                  showSideMenu={showSideMenu} 
                  setshowSideMenu={()=>setshowSideMenu(!showSideMenu)}  />}
                 
                  <Switch>
                        <Route exact path="/login">
                              <FillOtp 
                              homeLogo={appConfigData.home_logo} 
                              banners={banners} 
                              loading={loading} 
                              setLoading={setLoading}/>
                        </Route>
                        <ProtectedRoute exact  path="/home"
                        component={(routeProps) => (
                              <Home
                                {...routeProps}
                                games={games}
                                banners={banners}
                                bgColor={appConfigData?.bg_color}
                                view_all={appConfigData?.view_all}
                                setLoading={setLoading}
                                flow={appConfigData?.flow}
                                loading={loading}                  
                                view_btn_txt={appConfigData?.view_btn_txt}
                              />
                            )}                    
                        />
                        <ProtectedRoute exact path="/"
                        component={(routeProps) => (
                              <Home
                             {...routeProps}                            
                                games={games}
                                banners={banners}
                                bgColor={appConfigData?.bg_color}
                                view_all={appConfigData?.view_all}
                                setLoading={setLoading}
                                flow={appConfigData?.flow}
                                loading={loading}
                                view_btn_txt={appConfigData?.view_btn_txt}
                              />
                            )}                          
                        />
                         <Route path="/signup">
                              <Subscription
                               homeLogo={appConfigData.home_logo} 
                               banners={banners} 
                               loading={loading} 
                               setLoading={setLoading}/>
                        </Route>                   
                        <Route path="/terms">
                              <TermsAndConditions homeLogo={appConfigData.home_logo} banners={banners} loading={loading}/>
                        </Route>
                        <Route path="/modal">
                              <SubscriptionModal/>
                        </Route>
                        <Route path="/thanks">
                              <SubscriptionModal/>
                        </Route>
                        <Route path="/loading">
                              <SubscriptionLoader/>
                        </Route>
                        <Route path="/sorry">
                              <Sorry homeLogo={appConfigData.home_logo} banners={banners} loading={loading}  setLoading={setLoading}/>
                        </Route>
                           <ProtectedRoute path="/game/:title/:id"
                              component={(routeProps) => (
                              <Game
                              {...routeProps}
                              allGames={games}                           
                               view_all={appConfigData.view_btn_txt}
                              portrait_txt={appConfigData.portrait_txt}
                              play_text={appConfigData.play_text}
                              rotate_txt={appConfigData.rotate_txt}
                              landscape_txt={appConfigData.landscape_txt}
                              similar_cat_txt={appConfigData.similar_cat_txt}
                              logo={appConfigData.home_logo}
                              />
                            )}
                           />
                        <ProtectedRoute path="/profile"
                             component={(routeProps) =>
                               (<Profile 
                                    {...routeProps}                                 
                                    status_txt={appConfigData.status_txt}/>)}
                                   />
                         <ProtectedRoute exact path="/category"
                             component={(routeProps) =>
                               (<Category
                                    {...routeProps}
                                   
                                     play={appConfigData.play_text} />)}
                                    />
                        <ProtectedRoute path="/language"
                        component={(routeProps) =>
                               ( <Language {...routeProps}
                                    langObj={appConfigData?.sidemenu?.language}
                              />)}
                            />
                        <ProtectedRoute path="/unsub"
                            component={(routeProps) =>
                              ( <Unsubscribe
                              {...routeProps}               
                              unsub_mode={appConfigData?.unsub_mode}
                                unsubButtonText={appConfigData?.sidemenu?.unsub}
                                unsubText={appConfigData?.unsub_msg_txt}
                                status_txt={appConfigData?.status_txt}
                                />
                              )}
                             />
                        <Route path="*">
                              <NotFound logo={appConfigData?.home_logo}/>
                        </Route>
                        
                  </Switch>
            </section>
      );
}
export default App
